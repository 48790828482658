:root {
  --primary: #199FFF;
  --title: #265CFF;
  --title-desk: 16pt;
  --subtitle-grey: #959595;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.class__header {
  flex-shrink: 0;
  background-color: #037cf5;
}

.container {
  padding-top: 2em;
  padding-bottom: 2em;
}

.nav__bar {
  background: var(--primary);
  padding: 2rem;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
}

.footer__bar {
  background: #199FFF;
  padding: 2rem;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
}

.logo {
  width: 35%;
}

.margin-auto{
  margin: auto;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 10%;
  }

  .footer__bar {
    background: #199FFF;
    padding: 2rem;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.1);
    clear: both;
    position: relative;
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .logo {
    margin: 0 auto;
    display: block;
  }
}

.cover__page {
  background-image: url('./assets/img/home_movil.png');
  background-repeat: no-repeat;
  background-size: 100%;
  min-height: 80vh;
  background-color: #199FFF;
}

.desktop_info {
  display: none;
  color: white;
}

.mobile_info {
  text-align: center;
  margin-top: 20%;
  display: block;
  color: white;
}

.contratar__Web {
  display: none;
}

.poster__ad {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1rem;
}

.grid_double {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  min-height: 40vh;
  height: inherit;
}

.grid_double div {
  display: flex;
  flex-direction: column;
  padding: 2em;
}

.grid_double div div {
  flex: 1 1;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.grid_double div div div {
  padding: 40% 0%;
}

@media screen and (min-width: 768px) {

  .contratar__Web {
    display: block;
    font-size: 1.8em;
  }

  .poster__ad {
    height: 50vh;
  }

  .cover__page {
    background-image: url('./assets/img/home.png');
    background-repeat: no-repeat;
    background-size: 100%;
    min-height: 1000px;
    height: unset;
  }

  .grid_double div div {
    flex: 1 1;
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .grid_double {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    min-height: 120vh;
    height: inherit;
  }

  .contratar__mobil {
    display: none;
  }

  .contratar__mobil__a {
    display: none;
  }

  .desktop_info {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .mobile_info {
    display: none;
  }
}

.contratar__mobil__a {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}

.content__poster {
  background: #f8f8f8;
  max-height: 30%;
  padding: 1rem;
  position: absolute;
  bottom: 7rem;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.content__poster p {
  text-align: center;
  font-size: 13px;
}

.content__poster .txt__blue {
  color: var(--title);
  font-size: var(--title-desk);
  font-size: 18px;
}

@media screen and (min-width: 768px) {
  .content__poster .txt__blue {
    color: var(--title);
    font-size: var(--title-desk);
    font-size: 18px;
  }
}

.content__poster .btn-home {
  background: #265CFF;
  opacity: .8;
  margin-top: 1rem;
}

.data__contrat-cont {
  padding: 2rem;
}

.grid__contrat {
  display: inherit;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;

}

@media screen and (min-width: 768px) {
  .data__contrat-cont {
    padding: 2rem;
  }

  .grid__contrat {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;

  }
}

.data__contrat-cont .title__form {
  color: var(--title);
  font-size: 11pt;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

@media screen and (min-width: 768px) {

  .data__contrat-cont .bx__btn-next {
    display: flex-end;
  }

  .data__contrat-cont .bx__btn-next {
    display: flex;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    padding: 1rem;
  }

  .data__contrat-cont .buttons__form-data button {
    margin-right: 10px;
  }

  .data__contrat-cont .title__form {
    font-size: 14pt;
  }
}

.data__contrat-cont .sb__title_table {
  margin-bottom: 1rem;
  color: #6e6e6e;
  font-size: 12px;
}

.container__btns-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
}

.btn-sub-text {
  font-size: .700rem;
}

.data__contrat-cont .bx__btn-next {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
}

.data__contrat-cont .bx__btn-next button {
  background-color: var(--primary);
  border: none;
  opacity: .8;

}

/* table */
.table__info {
  font-size: 10px;
  text-align: center;
}

td input[type="checkbox"] {
  margin: 0 auto;
  display: block;
  cursor: pointer;
}

/* Form */
label {
  font-size: 14px;
}

.container__btns-info button {
  width: 45%;
  padding: 5px;
  font-size: 15px;
}

.btn {
  width: 45%;
}

.btn-home {
  width: 100%;
}

.disabled-link {
  pointer-events: none;
}

.title__time {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
}

.date__range-cont {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* calendar & time picker */
.calendar__cont {
  display: grid;
}

@media screen and (min-width: 768px) {
  .calendar__cont {
    grid-template-columns: repeat(2, 1fr);
  }
}

.time__picker-cont {
  display: flex;
}

.calendar__cont .time-range {
  padding: 1rem;
  margin: 0 auto;
  width: 100%;
}

.calendar__cont .time-range label {
  padding: 1rem;
}

/* calendar and day picker */
.container__calendar {
  width: 100%;
  display: grid;
  justify-content: center;
}

.container__calendar li {
  list-style: none;
}

.calendar {
  width: 100%;
  margin: 0 auto;
}

.inline-group {
  max-width: 10.5rem;
  padding: .5rem;
}

.inline-group .form-control {
  text-align: right;
  padding: 0.375rem 0.1rem;
}

.form-control[type="number"]::-webkit-inner-spin-button,
.form-control[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



.btn-outline-secondary {
  width: unset !important;
}

.Drop__file {
  padding: 2rem;
  max-height: 100%;
}

.Drop__file .title__video {
  color: var(--title);
  font-size: 22px;
}

.dobule_section_grid {
  display: inherit;
}

.triple_section_grid {
  display: inherit;
}

@media (min-width: 768px) {
  .dobule_section_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }

  .triple_section_grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 2rem;
  }
}

.title__video {
  color: var(--title);
  font-size: 14px;
}

.previewImg {
  max-width: 100%;
}

video {
  max-width: 100%;
}

.container__drop-file {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border-width: 2;
  border-radius: 2;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  min-height: 30vh;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container__drop-file img {
  max-width: 100%;
}

.container__drop-file .box__drop-zone {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
}

.container__drop-file .txt-upload {
  color: var(--title);
  cursor: pointer;
  font-size: 22px;
}

.container__plan-mensual {
  padding: 2rem;
}

.mensual_plan_dobule_section_grid {
  display: inherit;
}


@media (min-width: 768px) {
  .mensual_plan_dobule_section_grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
  }
}

.title {
  color: var(--title);
}

.subtitle__plan-mensual {
  color: var(--subtitle-grey);
}

.container__plan-mensual .card {
  margin-top: 1rem;
  cursor: pointer;
}

.select__item {
  background-color: #265CFF;
  color: #fff;
}

.select__item .card-title {
  color: #fff;
}

.container__plan-mensual .card .card-text {
  font-size: 12px;
  text-align: justify;
}

.container__plan-mensual .card .row {
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* btns */
.cont__plan-men {
  width: 100%;
  padding: 1rem;
}

.btns__plan-men {
  display: flex;
  justify-content: space-between;
}

.cont__plan-men .btns__plan-men .btn-next {
  background: var(--primary);
  opacity: .8;
  color: #fff;
}

.cont__plan-men .btns__plan-men .btn {
  width: 30%;
}

.display-columns-wrapp {
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1em;
}

.display-columns-wrapp>div {
  max-width: 170px;
  flex: 1;
}

.icon-button {
  border-radius: 30px;
  color: #64748b;
  background: #f1f5f9;
  border: none;
}

.icon-button:hover {
  background: red;
  color: white;
}

.required:after {
  content: " *";
  color: red;
}

.add-background {
  border: solid black 1px;
  background-image: url('./assets/img/billboard.png');

}

.add-container {
  position: relative;
  overflow: hidden;
  min-height: 76%;
  width: 100%;
}

.add-container .previewImg {
  padding: 11% 0 0 11%;
  width: 33.5%;
}

.add-container .background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.errorMessage {
  font-weight: bold;
  color: red;
}

.overview-button {
  z-index: 1000000;
  position: fixed;
  bottom: 28%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* 2k desktop screens */
@media (max-width: 2560px) {
  .add-container {
    position: relative;
    overflow: hidden;
    min-height: 76%;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 8%;
  }

  .overview-button {
    bottom: 27%;
  }
}

/* small desktop screens */
@media (max-width: 1920px) {
  .add-container {
    position: relative;
    overflow: hidden;
    min-height: 76%;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 1%;
  }

  .overview-button {
    bottom: 18%;
  }
}

/* big tablet screens */
@media (max-width: 1280px) {
  .add-container {
    position: relative;
    overflow: hidden;
    min-height: 76%;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 6%;
  }

  .overview-button {
    bottom: 5%;
  }
}

/* tablet screens */
@media (max-width: 1024px) {
  .add-container {
    position: relative;
    overflow: hidden;
    min-height: 76%;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 16%;
  }
}

/* big mobile screens */
@media (max-width: 768px) {
  .add-container {
    position: relative;
    overflow: hidden;
    height: 330px;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 52%;
  }
}

/* small mobile screens */
@media (max-width: 480px) {
  .add-container {
    position: relative;
    overflow: hidden;
    height: 330px;
    width: 100%;
  }

  .add-container .previewImg {
    padding: 11% 0 0 11%;
    width: 33.5%;
  }

  .home-button-position {
    max-width: 150px;
    position: absolute;
    bottom: 12%;
  }

  .contratar__mobil__a {
    bottom: 40%;
  }
}